import { Component, OnInit, ViewChild } from '@angular/core';
import { merge, Observable, of as observableOf } from "rxjs";
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute,Router } from '@angular/router';
import { GalleryService } from "../../../services/gallery/gallery.service";
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-gcreate',
  templateUrl: './gcreate.component.html',
  styleUrls: ['./gcreate.component.css']
})
export class GcreateComponent implements OnInit {

  apiUrl: string = environment.apiUrl;
  photoUseInIcon: string;
  image: any;
  gallerycreate: any;

  @ViewChild("fileInput") fileInput;

  constructor(
    private GalleryService: GalleryService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router :Router
  ) { }

  ngOnInit() {
  }

  public createGallery() {
    this.GalleryService.gallery_create(this.image).subscribe((response) => {
      this.gallerycreate = response;
      if (this.gallerycreate.status === "ok") {
        this.toastr.success('Success', this.gallerycreate.message);
      }
    }, err => {
      this.toastr.error('Error', "Something Went Wrong, We Canot Process your request right now.");
      this.toastr.error('Error', this.gallerycreate.message);
    });
  }

  onChangeFile(): void {
    let fi = this.fileInput.nativeElement;
    if (fi.files && fi.files[0]) {
      let fileToUpload = fi.files[0];
      console.log(fileToUpload);
      this.GalleryService
        .uploadGalleryImages(fileToUpload)
        .subscribe(res => {
          console.log(res);
          if (res.status == 'ok') {
            this.photoUseInIcon = this.apiUrl + res.data.image_location;
            console.log(this.photoUseInIcon);
            this.image = res.data.image_location;
            this.toastr.success('Success', "Image Successfully uploaded.");
            // this.router.navigate(['home/news/ndashboard'])

          } else {
            this.toastr.error('Error', "Something Went Wrong, We Canot Process your request right now.");
            this.toastr.error('Error', res.message);
          }
        });
    }
  }

}
